import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';

export interface BananaRecordItem {
  id: string;
  name: string;
  logo: string;
  level: string;
  description: string;
  amount: string;
  gold: string;
  sendCount: string;
  haveCount: string;
  onlineGold: string;
  profit: string;
  theme: string;
}
export interface WithdrawRecordDTOItem {
  id: string;
  tgId: string;
  amount: number;
  coin: string;
  status: 'WITHDRAW_REQUEST' | 'WITHDRAW_APPROVE' | 'WITHDRAW_REJECT';
  approvedTime: string;
  createdTime: string;
  updatedTime: string;
}
export interface UserDTO {
  id: string;
  tgId: string;
  name: string;
  registerTime: string;
  gold: string;
  bananaCount: string;
  bananaOnlineId: string;
}
export interface WalletDTO {
  tgId: string;
  depositAmount: string;
  withdrawAmount: string;
  frozenAmount: string;
  amount: string;
  coin: string;
  status: number;
  address?: string;
  createdTime: string;
  updatedTime: string;
  profit: string;
  profitPineapple: string;
  profitGold: string;
}
export interface AddressItem {
  address: string;
  createdTime: string;
  name: string;
  tgId: string;
  updatedTime: string;
}
export const fetchBagList = () => {
  return request<{
    addressList: AddressItem[];
    userDTO: UserDTO;
    bananaRecordList: BananaRecordItem[];
    walletDTO: WalletDTO;
  }>({
    url: '/api/mini-api/banana/list',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchSell = (data: { id: number; count: number }) => {
  return request<{}>({
    url: '/api/mini-api/banana/sell',
    data: data,
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchAutoPlay = (data: { id: number; up: boolean }) => {
  return request<{}>({
    url: '/api/mini-api/banana/auto-play',
    data: data,
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchWithdraw = (data: {
  amount: number;
  coin: 'USDT';
  address: string;
  name: string;
}) => {
  return request<{}>({
    url: '/api/mini-api/payment/withdraw',
    data: data,
    method: 'post',
    suppressWarning: false,
  });
};

export interface FetchWithdrawLogsProps {
  preId?: string;
  nextId?: string;
  size: number;
}

export const fetchWithdrawLogs = (data: FetchWithdrawLogsProps) => {
  return request<{
    withdrawRecordDTOList: WithdrawRecordDTOItem[];
  }>({
    url: '/api/mini-api/payment/withdraw-list',
    data: data,
    method: 'post',
    suppressWarning: false,
  });
};

export const fetchClaim = () => {
  return request<{
    profit: string;
  }>({
    url: '/api/mini-api/banana/claim',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const useWithdrawLogs = (data: FetchWithdrawLogsProps) => {
  return useQuery({
    queryKey: ['withdrawLogs'],
    queryFn: () => fetchWithdrawLogs(data),
  });
};

export const useBagList = () => {
  return useQuery({
    queryKey: ['bagList'],
    queryFn: () => fetchBagList(),
  });
};
